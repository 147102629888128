import './index.scss';


const Footer = () => {
	return (
		<div className="footer-container">
			<a href={"https://portfolio.luctatu.fr/"}> Actibus immensis urbs fulget
				Massiliensis </a>
		</div>
	);
}

export default Footer;
