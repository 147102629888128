import './index.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Footer from "../Footer";

const LoginWallpaper = ({onLogin, showLoginForm, setShowLoginForm, error}) => {
	const [loginData, setLoginData] = useState({
		email: '',
		password: ''
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		setLoginData(prev => ({
			...prev,
			[name]: value
		}));
	};

	const handleLoginSubmit = (e) => {
		e.preventDefault();
		onLogin(loginData.email, loginData.password);
	};

	const handleAbort = () => {
		setShowLoginForm(false);
		setLoginData({email: '', password: ''});
	};

	return (
		<div className="login-container">
			<div className="header-overlay">
				<h1>My Work Space</h1>
			</div>

			<div className="login-content">
				<h1>Welcome to My Work Space</h1>

				{!showLoginForm ? (
					<button
						className="login-button"
						onClick={() => setShowLoginForm(true)}
					>
						Login
					</button>
				) : (
					<div className="login-form-container">
						<form onSubmit={handleLoginSubmit} className="login-form">
							<div className="form-group">
								<input
									type="email"
									name="email"
									value={loginData.email}
									onChange={handleChange}
									placeholder="Email"
									required
								/>
							</div>
							<div className="form-group">
								<input
									type="password"
									name="password"
									value={loginData.password}
									onChange={handleChange}
									placeholder="Password"
									required
								/>
							</div>
							{error && <div className="error-message">{error}</div>}
							<div className="form-actions">
								<button
									type="submit"
									className="login-button"
								> Login
								</button>
								<button
									type="button"
									className="abort-button"
									onClick={handleAbort}
								>
									Abort
								</button>
							</div>
						</form>
					</div>
				)}
			</div>

			<div className="footer-overlay">
				<Footer/>
			</div>
		</div>
	);
};

LoginWallpaper.propTypes = {
	onLogin: PropTypes.func.isRequired,
	showLoginForm: PropTypes.bool.isRequired,
	setShowLoginForm: PropTypes.func.isRequired,
	error: PropTypes.string.isRequired
};

export default LoginWallpaper;
