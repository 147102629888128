import './App.scss';

import React, {useEffect, useState} from 'react';

import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {auth, db} from './firebase';
import Cookies from 'js-cookie';

import Header from './containers/Header';
import Body from './containers/Body';
import Footer from './containers/Footer';
import LoginWallpaper from './containers/LoginWallpaper';
import AddLink from "./components/AddLink";
import {sortCategories} from "./Tools";
import {collection, getDocs} from "firebase/firestore";

const App = () => {
	const [showLoginForm, setShowLoginForm] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [showAddLink, setShowAddLink] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [error, setError] = useState('');

	const [categories, setCategories] = useState([]);
	const [links, setLinks] = useState([]);

	const setCookies = (user) => {
		if (user) {
			Cookies.set('authUser', JSON.stringify({
				uid: user.uid,
				email: user.email
			}), {
				expires: 7,
				secure: true,
				sameSite: 'strict',
				path: '/'
			});
		} else {
			Cookies.remove('authUser', {path: '/'});
		}
	};

	useEffect(() => {
		const existingCookie = Cookies.get('authUser');
		if (existingCookie) {
			try {
				const userData = JSON.parse(existingCookie);
				setIsLoggedIn(!!userData);
			} catch (error) {
				setError(`Error parsing auth cookie: ${error}`);
				Cookies.remove('authUser', {path: '/'});
			}
		}
		if (isLoggedIn) {
			getCategories().then();
			getLinks().then();
		}
	}, [isLoggedIn]);

	const getCategories = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, 'categories'));
			const mapCategoriesResponse = querySnapshot.docs
				.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

			const sortedCategories = sortCategories(mapCategoriesResponse);
			setCategories(sortedCategories);
		} catch (error) {
			setError(`Error while getting Categories from firebase: ${error}`)
		}
	};

	const getLinks = async () => {
		try {
			const querySnapshot = await getDocs(collection(db, 'links'));
			setLinks(querySnapshot.docs
				.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}))
			);
		} catch (error) {
			setError(`Error while getting links from firebase: ${error}`)
		}
	}

	const onLogin = async (email, password) => {
		try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			setCookies(userCredential.user);
			setIsLoggedIn(true);
			setShowLoginForm(false);
		} catch (error) {
			setError(
				error.code === 'auth/invalid-credential'
					? 'Invalid email or password'
					: 'An error occurred during login'
			);
		}
	};

	const handleLogout = async () => {
		await signOut(auth);
		setIsLoggedIn(false);
		setCookies(null);
	};

	return (
		<div className="app">
			{!isLoggedIn ? (
				<LoginWallpaper
					onLogin={onLogin}
					showLoginForm={showLoginForm}
					setShowLoginForm={setShowLoginForm}
					error={error}
				/>
			) : (
				<>
					<div className="app-header">
						<Header
							isEdit={isEdit}
							setIsEdit={setIsEdit}
							onLogout={handleLogout}
							setShowAddLink={setShowAddLink}
							showAddLink={showAddLink}
						/>
					</div>
					<div className="app-body">
						{showAddLink && (
							<AddLink
								categories={categories}
								setCategories={setCategories}
								links={links}
								setLinks={setLinks}
								setShowAddLink={setShowAddLink}
								setError={setError}
							/>
						)}
						<Body
							isEdit={isEdit}
							categories={categories}
							setCategories={setCategories}
							links={links}
							setLinks={setLinks}
							error={error}
							setError={setError}
						/>
					</div>
					<div className="app-footer">
						<Footer/>
					</div>
				</>
			)}
		</div>
	);
};

export default App;
