import './index.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

const EditLink = ({link, onClose, onSave, onRemove}) => {
	const [linkData, setLinkData] = useState({
		id: link.id,
		name: link.name,
		url: link.url,
		image: link.image,
		category: link.category
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		setLinkData(prev => ({
			...prev,
			[name]: value
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSave({...link, ...linkData});
	};

	return (
		<Modal onClose={onClose}>
			<form onSubmit={handleSubmit} className="edit-link-form">
				<h2>Edit Link</h2>
				<div className="form-group">
					<label htmlFor="name">Name:</label>
					<input
						type="text"
						id="name"
						name="name"
						value={linkData.name}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="url">URL:</label>
					<input
						type="url"
						id="url"
						name="url"
						value={linkData.url}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="image">Image URL:</label>
					<input
						type="file"
						name="image"
						placeholder="Image"
						onChange={(e) => setLinkData(prev => ({
							...prev,
							image: e.target.files[0]
						}))}
					/>
				</div>
				<div className="form-actions">
					<button type="submit" className="btn-save">Save</button>
					<button
						type="button"
						className="btn-remove"
						onClick={() => onRemove(link)}
					>
						Remove
					</button>
					<button
						type="button"
						className="btn-cancel"
						onClick={onClose}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

EditLink.propTypes = {
	link: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
		image: PropTypes.string.isRequired,
		category: PropTypes.string.isRequired
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default EditLink;
