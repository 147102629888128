import './index.scss';

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';

const EditCategory = ({categoryObject, onClose, onSave, onRemove}) => {
	const [categoryData, setCategoryData] = useState({
		category: categoryObject.category,
		idx: categoryObject.idx
	});

	const handleChange = (e) => {
		const {name, value} = e.target;
		setCategoryData(prev => ({
			...prev,
			[name]: value
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSave({
			...categoryObject,
			...categoryData,
			oldCategory: categoryObject.category
		});
	};

	return (
		<Modal onClose={onClose}>
			<form onSubmit={handleSubmit} className="edit-category-form">
				<h2>Edit Category</h2>
				<div className="form-group">
					<label htmlFor="category">Category Name:</label>
					<input
						type="text"
						id="category"
						name="category"
						value={categoryData.category}
						onChange={handleChange}
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="idx">Position:</label>
					<input
						type="number"
						id="idx"
						name="idx"
						value={categoryData.idx}
						onChange={handleChange}
						required
						min="0"
					/>
				</div>
				<div className="form-actions">
					<button type="submit" className="btn-save">
						Save
					</button>
					<button
						type="button"
						className="btn-remove"
						onClick={() => onRemove(categoryObject)}
					>
						Remove
					</button>
					<button
						type="button"
						className="btn-cancel"
						onClick={onClose}
					>
						Cancel
					</button>
				</div>
			</form>
		</Modal>
	);
};

EditCategory.propTypes = {
	categoryObject: PropTypes.shape({
		id: PropTypes.string.isRequired,
		category: PropTypes.string.isRequired,
		idx: PropTypes.number.isRequired
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired
};

export default EditCategory;
