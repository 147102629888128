import './index.scss';

import React, {useMemo, useState} from "react";
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {db, deleteDoc, doc, query, storage, updateDoc, where} from '../../firebase';

import {
	MIN_CATEGORIES,
	padCategories,
	sortCategories,
	sortLinks,
	SUPPORTED_IMAGE_FORMATS,
	uploadDefaultImage
} from '../../Tools';
import {faEllipsis, faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";

import EditCategory from "../../components/EditCategory";
import {collection, getDocs} from "firebase/firestore";
import EditLink from "../../components/EditLink";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import default_img from "../../assets/default_img.png";

const Body = ({isEdit, categories, setCategories, links, setLinks, error, setError}) => {
	const [editingCategoryObject, setEditingCategoryObject] = useState(null);
	const [editingLinkObject, setEditingLinkObject] = useState(null);

	const handleCloseModal = () => {
		setEditingCategoryObject(null);
		setEditingLinkObject(null);
	};

	const categoriesToDisplay = useMemo(() =>
			sortCategories(padCategories(categories, MIN_CATEGORIES)),
		[categories]
	);

	const linksByCategory = useMemo(() => {
		if (!links || !Array.isArray(links)) return {};

		return categoriesToDisplay.reduce((acc, categoryObj) => {
			const {category} = categoryObj;
			acc[category] = sortLinks(links.filter(link => link.category === category));
			return acc;
		}, {});
	}, [links, categoriesToDisplay]);

	const handleUpdateLink = async (updatedLink) => {
		if (!updatedLink?.id) {
			setError("Invalid link ID for update");
			return;
		}

		const image = updatedLink.image;

		if (image && !(
			SUPPORTED_IMAGE_FORMATS.includes(image.type) ||
			(typeof image === 'string' && image.startsWith('https://firebasestorage.googleapis.com'))
		)) {
			alert('Unsupported image format. Please upload a JPEG or PNG.');
			return;
		}

		let downloadUrl;

		if (image) {
			if (typeof image !== 'string') {
				const storageRef = ref(storage, `image_link/${image.name}`);
				const snapshot = await uploadBytes(storageRef, image);
				downloadUrl = await getDownloadURL(snapshot.ref);
			} else {
				downloadUrl = image;
			}
		} else {
			downloadUrl = await uploadDefaultImage(default_img, storage);
		}

		const linkData = {
			category: updatedLink.category,
			name: updatedLink.name,
			url: updatedLink.url,
			image: downloadUrl
		};

		try {
			await updateLinkInFirebase(updatedLink.id, linkData)
		} catch (error) {
			setError(`Error while updating link in firebase: ${error}`);
		} finally {
			handleCloseModal();
		}
	};

	const handleRemoveLink = async (linkToRemove) => {
		if (!window.confirm(`Are you sure you want to delete "${linkToRemove.name}"?`)) {
			return;
		}

		try {
			const categoryToCheck = linkToRemove.category;
			const linkRemoved = await removeLinkFromFirebase(linkToRemove);
			if (linkRemoved) {
				setLinks(prevLinks => {
					const updatedLinks = prevLinks.filter(link => link.id !== linkToRemove.id);
					const hasLinksInCategory = updatedLinks.some(link => link.category === categoryToCheck);

					if (!hasLinksInCategory) {
						const categoryToRemove = categories.find(category => category.category === categoryToCheck)
						removeCategoryFromFirebase(categoryToRemove);
					}
					return updatedLinks;
				})
			}
		} catch (error) {
			setError(`Error while removing link and category: ${error}`);
		} finally {
			handleCloseModal();
		}
	};

	const updateLinksWhenCategoryChanged = async (categoryObject) => {
		try {
			const querySnapshot = await getDocs(
				query(collection(db, 'links'),
					where('category', '==', categoryObject.oldCategory))
			);

			if (!querySnapshot.empty) {
				for (const link of querySnapshot.docs) {
					const linkDB = link.data();
					const linkData = {
						name: linkDB.name,
						url: linkDB.url,
						image: linkDB.image,
						category: categoryObject.category
					};

					await updateLinkInFirebase(link.id, linkData)

					setLinks(prevLinks =>
						prevLinks.map(existingLink =>
							existingLink.id === link.id
								? {...existingLink, category: categoryObject.category}
								: existingLink
						)
					);
				}
			}
		} catch (error) {
			alert(`Error while updating links when category changed on firebase: ${error}`);
		}
	};

	const updateLinkInFirebase = async (linkId, linkData) => {
		try {
			const linkRef = doc(db, 'links', linkId);
			await updateDoc(linkRef, linkData);
			setLinks(prevLinks =>
				prevLinks.map(link =>
					link.id === linkId ? {...link, ...linkData} : link
				)
			);
		} catch (error) {
			setError(`Error while updating Link to firebase: ${error}`);
		}
	}

	const removeLinkFromFirebase = async (linkData) => {
		if (!linkData?.id) {
			setError('Invalid link ID for removal');
			return;
		}
		try {
			const linkRef = doc(db, 'links', linkData.id);
			await deleteDoc(linkRef);

			setLinks(prevLinks =>
				prevLinks.filter(link => link.id !== linkData.id)
			);
			return true;
		} catch (error) {
			setError(`Error while removing Link from Firebase: ${error}`);
			return false;
		}
	};

	const handleRemoveCategory = async (categoryData) => {
		if (!categoryData?.id || !categoryData?.category) {
			setError("Invalid category data for removal");
			return;
		}

		if (!window.confirm(`Are you sure you want to delete the category "${categoryData.category}"?`)) {
			return;
		}

		const categoryRemoved = await removeCategoryFromFirebase(categoryData);
		if (categoryRemoved) {
			await removeLinksWhenCategoryRemoved(categoryData);

			setCategories((prevCategories) => {
				return prevCategories.filter((category) => category.id !== categoryData.id)
					.map((category) => {
						if (category.idx > categoryData.idx) {
							return {...category, idx: category.idx - 1};
						}
						return category;
					});
			});
		}

		handleCloseModal();
	};

	const removeCategoryFromFirebase = async (categoryData) => {
		if (!categoryData?.id) {
			setError('Invalid category ID for removal');
			return false;
		}

		try {
			const categoryRef = doc(db, 'categories', categoryData.id);
			await deleteDoc(categoryRef);

			setCategories(prevCategories =>
				prevCategories.filter(category => category.id !== categoryData.id)
			);
			return true;
		} catch (error) {
			setError(`Error while removing Category from Firebase: ${error}`);
			return false;
		}
	};

	const removeLinksWhenCategoryRemoved = async (categoryObject) => {
		try {
			const querySnapshot = await getDocs(
				query(collection(db, 'links'),
					where('category', '==', categoryObject.category))
			);

			const deletePromises = querySnapshot?.docs?.map(doc =>
				deleteDoc(doc.ref)
			) ?? [];

			await Promise.all(deletePromises);

			setLinks(prevLinks =>
				prevLinks.filter(link => link.category !== categoryObject.category)
			);
		} catch (error) {
			setError(`"Error while removing links on firebase: ${error}`);
		}
	};

	const handleSaveCategory = async (categoryData) => {
		if (!categoryData?.category || !categoryData?.idx) {
			setError("Invalid category data for update");
			return;
		}

		const categoryUpdated = await updateCategoryInFirebase(categoryData);
		if (categoryUpdated) {
			await updateLinksWhenCategoryChanged(categoryData);

			setCategories((prevCategories) => {
				const updatedCategories = prevCategories.map((category) =>
					category.id === categoryData.id
						? {...category, idx: categoryData.idx, category: categoryData.category}
						: category
				);

				const sortedCategories = updatedCategories.sort((a, b) => a.idx - b.idx);

				const finalCategories = sortedCategories.map((category, index) => ({
					...category,
					idx: index + 1,
				}));

				finalCategories.forEach(async (category) => {
					const previousCategory = prevCategories.find(c => c.id === category.id);
					if (category.id !== categoryData.id &&
						(category.idx !== previousCategory?.idx || category.category !== previousCategory?.category)) {
						await updateCategoryInFirebase(category);
					}
				});

				return finalCategories;
			});
		}

		handleCloseModal();
	};

	const updateCategoryInFirebase = async (categoryObject) => {
		if (!categoryObject?.id) {
			setError('Invalid category ID for update');
			return;
		}

		const categoryData = {
			category: categoryObject.category,
			idx: categoryObject.idx
		};

		const categoryId = categoryObject.id;
		const categoryRef = doc(db, 'categories', categoryId);
		try {
			await updateDoc(categoryRef, categoryData);
			return true;
		} catch (error) {
			setError(`Error while updating Category to Firebase: ${error}`);
		}
	};

	return (
		<>
			{error && <div className="error-message">{error}</div>}
			<div className="categories-grid">
				{categoriesToDisplay.map((categoryObj) => (
					<div
						key={categoryObj.idx}
						className={`category-container ${categoryObj.category === "" ? "empty-category" : ""}`}
					>
						{categoryObj.category && (
							<>
								<div className="category-header">
									<h2 className="category-title">{categoryObj.category}</h2>
									{isEdit && (
										<FontAwesomeIcon icon={faEllipsis}
										                 className="category-icon"
										                 onClick={(e) => {
											                 e.preventDefault();
											                 setEditingCategoryObject(categoryObj);
										                 }}/>
									)}
								</div>
								{linksByCategory[categoryObj.category]?.map((link) => (
									<div className="link-container" key={link.id}>
										<a className="link"
										   rel="noopener noreferrer"
										   href={link.url}
										   target="_blank"
										>
											<img src={link.image} className="link-image" alt=""/>
											<span className="link-text">{link.name}</span>
										</a>
										{isEdit && (
											<FontAwesomeIcon icon={faEllipsisVertical}
											                 className="link-icon"
											                 onClick={(e) => {
												                 e.preventDefault();
												                 setEditingLinkObject(link);
											                 }}/>
										)}
									</div>
								))}
							</>
						)}
					</div>
				))}
			</div>

			{editingCategoryObject && (
				<EditCategory
					categoryObject={editingCategoryObject}
					onClose={handleCloseModal}
					onSave={handleSaveCategory}
					onRemove={handleRemoveCategory}
				/>
			)}

			{editingLinkObject && (
				<EditLink
					link={editingLinkObject}
					onClose={handleCloseModal}
					onSave={handleUpdateLink}
					onRemove={handleRemoveLink}
				/>
			)}
		</>
	);
};

Body.propTypes = {
	isEdit: PropTypes.bool.isRequired,
	categories: PropTypes.array.isRequired,
	setCategories: PropTypes.func.isRequired,
	links: PropTypes.array.isRequired,
	setLinks: PropTypes.func.isRequired,
	error: PropTypes.string.isRequired,
	setError: PropTypes.func.isRequired,
};

export default Body;
