import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getStorage} from 'firebase/storage';
import {
	deleteDoc as firebaseDeleteDoc,
	doc as firestoreDoc,
	getFirestore,
	query as firestoreQuery,
	updateDoc as firestoreUpdateDoc,
	where as firestoreWhere
} from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyBHzZWkqbAXc5qxhL78iKRjjap3JJ7ILfE",
	authDomain: "myworkspace-8c820.firebaseapp.com",
	projectId: "myworkspace-8c820",
	storageBucket: "myworkspace-8c820.appspot.com",
	messagingSenderId: "948183443118",
	appId: "1:948183443118:web:79e2add833dc979f1bef3f"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const doc = firestoreDoc;
export const updateDoc = firestoreUpdateDoc;
export const query = firestoreQuery;
export const deleteDoc = firebaseDeleteDoc;
export const where = firestoreWhere;
