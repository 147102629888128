import './index.scss';

import React, {useState} from "react";
import PropTypes from 'prop-types';

import {db, storage} from "../../firebase";
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import {addDoc, collection} from 'firebase/firestore';
import default_img from '../../assets/default_img.png';

import {calculateNextIdx, getFormattedValue, SUPPORTED_IMAGE_FORMATS, uploadDefaultImage} from '../../Tools';

const AddLink = ({categories, setCategories, links, setLinks, setShowAddLink, setError}) => {
	const [formData, setFormData] = useState({
		name: '',
		url: '',
		category: '',
		image: null
	});
	const [suggestions, setSuggestions] = useState([]);
	const [showSuggestions, setShowSuggestions] = useState(false);

	const handleAddLink = async (e) => {
		e.preventDefault();

		const category = getFormattedValue(formData.category);
		let name = getFormattedValue(formData.name);
		const url = formData.url;
		const image = formData.image;

		if (category && name && url) {
			if (image && !SUPPORTED_IMAGE_FORMATS.includes(image.type)) {
				alert('Unsupported image format. Please upload a JPEG or PNG.');
				return;
			}

			const existingLinksInCategory = links.filter(link =>
				link.category === category && link.name.replace(/ \(\d+\)$/, '') === name
			);

			if (existingLinksInCategory.length > 0) {
				name = `${name} (${existingLinksInCategory.length + 1})`;
			}

			try {
				let newCategory = null;
				if (!categories.some(cat => cat.category === category)) {
					const nextIdx = calculateNextIdx(categories);
					newCategory = {
						category,
						idx: nextIdx
					};

					const response = await addDoc(collection(db, "categories"), newCategory);
					const newCategoryWithId = {
						...newCategory,
						id: response.id
					};

					setCategories(prevCategories => [...prevCategories, newCategoryWithId]);
				}

				let downloadUrl;

				if (image) {
					const storageRef = ref(storage, `image_link/${image.name}`);
					const snapshot = await uploadBytes(storageRef, image);
					downloadUrl = await getDownloadURL(snapshot.ref);
				} else {
					downloadUrl = await uploadDefaultImage(default_img, storage);
				}

				const newLink = {
					category,
					name,
					url,
					image: downloadUrl
				};

				const response = await addDoc(collection(db, 'links'), newLink);

				const newLinkWithId = {
					...newLink,
					id: response.id
				};

				setLinks(prevLinks => [...prevLinks, newLinkWithId]);
				setShowAddLink(false);

			} catch (error) {
				setError(`Failed to add new link: ${error}`);
			}
		} else {
			alert('All fields are required.');
		}
	};

	const handleChange = (e) => {
		const {name, value} = e.target;
		setFormData(prev => ({
			...prev,
			[name]: value
		}));

		if (name === 'category') {
			const searchTerm = value.toLowerCase().trim();

			const matchingCategories = categories.filter(cat =>
				cat?.category?.toLowerCase().includes(searchTerm)
			);

			const suggestionList = matchingCategories.map(cat => cat.category);

			setSuggestions(suggestionList);
			setShowSuggestions(value.length > 0 && suggestionList.length > 0);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setFormData(prev => ({
			...prev,
			category: suggestion
		}));
		setShowSuggestions(false);
	};

	return (
		<div className="addLink-container">
			<div className="addLink-form">
				<button className="close-button" onClick={() => setShowAddLink(false)}>
					×
				</button>
				<h2>Add New Link</h2>
				<form onSubmit={handleAddLink}>
					<div className="form-group category-input-container">
						<input
							type="text"
							name="category"
							value={formData.category}
							onChange={handleChange}
							placeholder="Category"
							required
							autoComplete="off"
						/>
						{showSuggestions && (
							<ul className="category-suggestions">
								{suggestions.map((suggestion, index) => (
									<li
										key={`${suggestion}-${index}`}
										onClick={() => handleSuggestionClick(suggestion)}
									>
										{suggestion}
									</li>
								))}
							</ul>
						)}
					</div>
					<p>
						<input
							type="text"
							name="name"
							placeholder="Link Name"
							value={formData.name}
							onChange={handleChange}
							required
						/>
					</p>
					<p>
						<input
							type="url"
							name="url"
							placeholder="URL"
							value={formData.url}
							onChange={handleChange}
							required
						/>
					</p>
					<p>
						<input
							type="file"
							name="image"
							placeholder="Image"
							onChange={(e) => setFormData(prev => ({
								...prev,
								image: e.target.files[0]
							}))}
						/>
					</p>
					<button type="submit">Add Link</button>
				</form>
			</div>
		</div>
	);
};

AddLink.propTypes = {
	categories: PropTypes.array.isRequired,
	setCategories: PropTypes.func.isRequired,
	links: PropTypes.array.isRequired,
	setLinks: PropTypes.func.isRequired,
	setShowAddLink: PropTypes.func.isRequired,
	setError: PropTypes.func.isRequired,
};

export default AddLink;
