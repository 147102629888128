import './index.scss';

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const Modal = ({children, onClose}) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, []);

	const handleBackdropClick = (e) => {
		if (e.target === e.currentTarget) {
			onClose();
		}
	};

	return (
		<div className="modal-backdrop" onClick={handleBackdropClick}>
			<div className="modal-content">
				{children}
			</div>
		</div>
	);
};

Modal.propTypes = {
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func.isRequired
};

export default Modal;
