import './index.scss';

import React from "react";
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faCirclePlus, faClose} from "@fortawesome/free-solid-svg-icons";
import Me from "../../assets/me.png";

const Header = ({isEdit, setIsEdit, onLogout, setShowAddLink, showAddLink}) => {
	return (
		<>
			<div className="left-btn-container">
				{isEdit ? (
					<button onClick={() => setShowAddLink(!showAddLink)} className="add-button">
						<FontAwesomeIcon icon={faCirclePlus}/>
					</button>
				) : (
					<button onClick={() => onLogout()} className="logout-button">
						<FontAwesomeIcon icon={faClose}/>
					</button>
				)
				}
			</div>
			<h1>My Work Space</h1>
			<div className="right-btn-container">
				{isEdit ?
					<div onClick={() => setIsEdit(false)} className="close-button">
						<FontAwesomeIcon icon={faClose}/>
					</div>
					:
					<div onClick={() => setIsEdit(true)} className="edit-button">
						<img src={Me} alt="edit-button"/>
					</div>
				}
			</div>
		</>
	);
}

Header.propTypes = {
	isEdit: PropTypes.bool.isRequired,
	setIsEdit: PropTypes.func.isRequired,
	onLogout: PropTypes.func.isRequired,
	setShowAddLink: PropTypes.func.isRequired,
	showAddLink: PropTypes.bool.isRequired,
};

export default Header;
